import React, { Component } from 'react'
import { Container, Row, Col,
    Form, Button } from 'react-bootstrap';
import SearchStyle from './search.module.css';
import { Redirect } from "react-router-dom";
import PinDropIcon from '@mui/icons-material/PinDrop';
import EventIcon from '@mui/icons-material/Event';
import LanIcon from '@mui/icons-material/Lan';
import SearchIcon from '@mui/icons-material/Search';

class Search extends Component {

    state = {
        search: false,
        where: "",
        month: "",
        type: ""
    }

    handelWhere = (e) => {
        let newState = Object.assign({}, this.state)
        newState.where = e.target.value
        this.setState(newState)
    }

    handelMonth = (e) => {
        let newState = Object.assign({}, this.state)
        newState.month = e.target.value
        this.setState(newState)
    }

    handleSearch = (e) => {
        e.preventDefault();
        this.setState({
            search: true
        })
    }

    render() {
        
        if(this.state.search){
            return (<Redirect to = {{
                pathname: '/search',
                state: this.state
            }}/>)
        }

        return (
            <Container>
                <Form onSubmit={this.handleSearch}>
                    <Row style={{backgroundColor: "#fff", marginTop: "-40px", boxShadow: "0 3px 13px 0 rgba(0,0,0,.07)", fontFamily: "Poppins"}}>
                        <Col md={3} className="p-0" style={{height: "80px", borderRight: "1px solid #f1f1f1"}}>
                            <Form.Group className={SearchStyle.formGroup}>
                                <PinDropIcon className={SearchStyle.iconStyle} />
                                <Form.Control onChange={(e) => this.handelWhere(e)} value={this.state.where} type="text" placeholder="Where" className={SearchStyle.inputStyle}></Form.Control>
                            </Form.Group >
                        </Col>
                        <Col md={3} className="p-0" style={{height: "80px", borderRight: "1px solid #f1f1f1"}}>
                            <Form.Group className={SearchStyle.formGroup}>
                                <EventIcon className={SearchStyle.iconStyle} />
                                <Form.Control onChange={(e) => this.handelMonth(e)} value={this.state.month} type="text" placeholder="Month" className={SearchStyle.inputStyle}></Form.Control>
                            </Form.Group >
                        </Col>
                        <Col md={3} className="p-0" style={{height: "80px", borderRight: "1px solid #f1f1f1"}}>
                            <Form.Group className={SearchStyle.formGroup}>
                                <LanIcon className={SearchStyle.iconStyle} />
                                <Form.Control type="text" placeholder="Type" className={SearchStyle.inputStyle}></Form.Control>
                            </Form.Group >
                        </Col>
                        <Col md={3} className="p-0" style={{height: "80px"}}>
                            <Form.Group style={{margin: "0", height: "100%"}}>
                                <Button type="submit" className={SearchStyle.btnSearch + " col-md-12"}><SearchIcon /> Rechercher</Button>
                            </Form.Group >
                        </Col>
                    </Row>
                </Form>
            </Container>
        )
    }
}

export default Search
