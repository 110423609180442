
export const REQUEST_HOME_FIRST_SECTION_DATA = "REQUEST_HOME_FIRST_SECTION_DATA"
export const RECEIVE_HOME_FIRST_SECTION_DATA = "RECEIVE_HOME_FIRST_SECTION_DATA"
export const REQUEST_HOME_FIRST_SECTION_ERROR = "REQUEST_HOME_FIRST_SECTION_ERROR"

export const REQUEST_HOME_SECEND_SECTION_DATA = "REQUEST_HOME_SECEND_SECTION_DATA"
export const RECEIVE_HOME_SECEND_SECTION_DATA = "RECEIVE_HOME_SECEND_SECTION_DATA"
export const REQUEST_HOME_SECEND_SECTION_ERROR = "REQUEST_HOME_SECEND_SECTION_ERROR"

export const REQUEST_GET_OFFER_DATA = "REQUEST_GET_OFFER_DATA"
export const RECEIVE_GET_OFFER_DATA = "RECEIVE_GET_OFFER_DATA"
export const REQUEST_GET_OFFER_ERROR = "REQUEST_GET_OFFER_ERROR"

export const REQUEST_GET_DESTINATION_DATA = "REQUEST_GET_DESTINATION_DATA"
export const RECEIVE_GET_DESTINATION_DATA = "RECEIVE_GET_DESTINATION_DATA"
export const REQUEST_GET_DESTINATION_ERROR = "REQUEST_GET_DESTINATION_ERROR"

export const REQUEST_GET_SIDER_DATA = "REQUEST_GET_SIDER_DATA"
export const RECEIVE_GET_SIDER_DATA = "RECEIVE_GET_SIDER_DATA"
export const REQUEST_GET_SIDER_ERROR = "REQUEST_GET_SIDER_ERROR"

export const REQUEST_SEARCH_DATA = "REQUEST_SEARCH_DATA"
export const RECEIVE_SEARCH_DATA = "RECEIVE_SEARCH_DATA"
export const REQUEST_SEARCH_ERROR = "REQUEST_SEARCH_ERROR"

export const REQUEST_LOGO_URL = "REQUEST_LOGO_URL"
export const RECEIVE_LOGO_URL = "RECEIVE_LOGO_URL"
export const REQUEST_LOGO_URL_ERROR = "REQUEST_LOGO_URL_ERROR"